// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/iframe-has-title */
import { LinksFunction, LoaderArgs, json } from '@shopify/remix-oxygen';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
} from '@remix-run/react';
import styles from '~/styles/app.css';
import toastStyles from 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { Theme, globalTheme } from './theme';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import InitialLoader from './components/InitialLoader';
import Layout from './layout';
import { MetaTags } from './components/MetaTags';
import { createHead } from 'remix-island';

export async function loader({ context: { env } }: LoaderArgs) {
  return json({
    ENV: {
      API_URL: env.PUBLIC_API_URL,
      COUNTDOWN_DATE: env.COUNTDOWN_DATE,
      PUBLIC_MYSTERY_COLLECTION_NAME: env.PUBLIC_MYSTERY_COLLECTION_NAME,
      PUBLIC_MYSTERY_COLLECTION_GROUPS: env.PUBLIC_MYSTERY_COLLECTION_GROUPS,
      PASSWORD: env.PASSWORD,
      PUBLIC_SHOW_PRODUCTS: env.PUBLIC_SHOW_PRODUCTS,
      ENABLE_SWEATER_SIZES: env.ENABLE_SWEATER_SIZES,
      ENABLE_TANK_TOP_SIZES: env.ENABLE_TANK_TOP_SIZES,
      ENABLE_CREW_NECK_SIZES: env.ENABLE_CREW_NECK_SIZES,
      GOOGLE_SERVICE_ACCOUNT_EMAIL: env.GOOGLE_SERVICE_ACCOUNT_EMAIL,
      GOOGLE_PRIVATE_KEY: env.GOOGLE_PRIVATE_KEY,
      GOOGLE_SHEET_ID: env.GOOGLE_SHEET_ID,
    },
  });
}

export const links: LinksFunction = () => {
  return [
    { rel: 'stylesheet', href: styles },
    { rel: 'stylesheet', href: toastStyles },
    {
      rel: 'preconnect',
      href: 'https://cdn.shopify.com',
    },
    {
      rel: 'preconnect',
      href: 'https://shop.app',
    },
    {
      rel: 'preload',
      href: '/fonts/HalvarBreit/HalvarBreit-Th.ttf',
      as: 'font',
      type: 'font/ttf',
      crossOrigin: 'anonymous',
    },
  ];
};

export const Head = createHead(() => (
  <>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width,initial-scale=1" />
    <MetaTags />
    <Meta />
    <Links />
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-Y371T6QH3Q"
    ></script>
    <script
      dangerouslySetInnerHTML={{
        __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', 'G-Y371T6QH3Q');
        `,
      }}
    ></script>
    <script
      dangerouslySetInnerHTML={{
        __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-W5B35RKP');
        `,
      }}
    ></script>
  </>
));

export default function App() {
  const data = useLoaderData<typeof loader>();
  const navigation = useNavigation();

  return (
    <>
      <Head />
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-W5B35RKP"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      <RecoilRoot>
        <ThemeProvider theme={globalTheme as Theme}>
          <InitialLoader />
          {navigation.state !== 'idle' ? <InitialLoader /> : null}
          <Layout>
            <Outlet />
          </Layout>
          <ToastContainer />
          <ScrollRestoration />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
            }}
          />
          <Scripts />
          <LiveReload />
        </ThemeProvider>
      </RecoilRoot>
    </>
  );
}
