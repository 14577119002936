import { breakpoints, breakpointValues } from './breakpoints';
import { colors } from './colors';
import { shadows } from './shadows';
import { zIndex } from './zIndex';
import { transitions } from './transitions';

export const globalTheme = {
  colors,
  breakpoints,
  shadows,
  breakpointValues,
  zIndex,
  transitions,
};

export type Theme = typeof globalTheme;
export type Colors = keyof typeof colors;
export type Shadows = keyof typeof shadows;
export type ZIndexes = keyof typeof zIndex;
export type Breakpoints = keyof typeof breakpoints;
export type Transitions = keyof typeof transitions;
