import { Colors } from '~/theme';
import BurgerButton from './BurgerButton';
import MobileMenu from './MobileMenu';

interface IMobileSidebar {
  isMobileNavigationOpen?: boolean;
  toggleMobileNavigation: (val: boolean) => void;
  fontColor?: Colors;
  activeTab?: string;
}

const MobileSidebar = ({
  fontColor,
  isMobileNavigationOpen,
  toggleMobileNavigation,
  activeTab,
}: IMobileSidebar) => {
  return (
    <>
      <MobileMenu isOpen={isMobileNavigationOpen} activeTab={activeTab} />
      <BurgerButton
        isOpen={isMobileNavigationOpen}
        setIsOpen={toggleMobileNavigation}
        fontColor={fontColor}
      />
    </>
  );
};

export default MobileSidebar;
