import { useEffect, useState } from 'react';

export const useDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const touchDevice =
      ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) ||
      'ontouchstart' in window;

    setIsTouchDevice(touchDevice);
  }, []);

  return { isTouchDevice };
};
