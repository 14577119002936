import Instagram from '~/components/icons/Instagram';
import Mail from '~/components/icons/Mail';
import Pinterest from '~/components/icons/Pinterest';
import Tiktok from '~/components/icons/Tiktok';

export const socialItems = [
  {
    key: 1,
    link: 'https://www.tiktok.com/@brokenplanet',
    icon: <Tiktok />,
  },
  {
    key: 2,
    icon: <Instagram />,
    link: 'https://www.instagram.com/brokenplanet/',
  },
  {
    key: 3,
    icon: <Pinterest />,
    link: 'https://www.pinterest.com/brokenplanet/',
  },
  {
    key: 4,
    icon: <Mail />,
    link: 'mailto:support@brokenplanet.com',
  },
];

export default socialItems;
