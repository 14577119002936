import React, { useEffect, useState } from 'react';
import gsap from 'gsap';

import styled from 'styled-components';

interface BadgeProps {
  count: number;
  children: React.ReactNode;
}

export const Badge: React.FC<BadgeProps> = ({ count, children }) => {
  const [prevCount, setPrevCount] = useState(count);

  useEffect(() => {
    if (count !== prevCount) {
      playAppearAnimation();
      setPrevCount(count);
    }
  }, [count, prevCount]);

  const playAppearAnimation = () => {
    gsap.to('.badge-count', {
      scale: 0.5,
      duration: 0.25,
      onComplete: () => {
        gsap.to('.badge-count', {
          scale: 1,
          duration: 0.25,
        });
      },
    });
  };
  return (
    <BadgeContainer>
      <BadgeCount className="badge-count">{count}</BadgeCount>
      {children}
    </BadgeContainer>
  );
};

export const BadgeContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 1.25rem;
`;

export const BadgeCount = styled.span`
  top: 0;
  transform: translate(50%, -50%);
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  border-radius: 50%;
  font-size: 0.625rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  background-color: ${({ theme }) => theme.colors.primaryDark};
  color: ${({ theme }) => theme.colors.white};
  right: -0.655rem;
  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: 0.5rem;
    top: 0.125rem;
    right: -0.455rem;
    width: 1.25rem;
    height: 1.25rem;
  }
`;
