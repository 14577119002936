import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    {...props}
  >
    <g clipPath="url(#instagram)">
      <path d="M8.125 2.083h8.75a6.046 6.046 0 0 1 6.042 6.042v8.75a6.042 6.042 0 0 1-6.042 6.042h-8.75a6.046 6.046 0 0 1-6.042-6.042v-8.75a6.042 6.042 0 0 1 6.042-6.042Zm-.208 2.084a3.75 3.75 0 0 0-3.75 3.75v9.166a3.748 3.748 0 0 0 3.75 3.75h9.166a3.75 3.75 0 0 0 3.75-3.75V7.917a3.748 3.748 0 0 0-3.75-3.75H7.917Zm10.052 1.562a1.302 1.302 0 1 1 0 2.604 1.302 1.302 0 0 1 0-2.604ZM12.5 7.292a5.208 5.208 0 1 1 0 10.416 5.208 5.208 0 0 1 0-10.416Zm0 2.083a3.125 3.125 0 1 0 0 6.25 3.125 3.125 0 0 0 0-6.25Z" />
    </g>
    <defs>
      <clipPath id="instagram">
        <path d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgInstagram;
