export const shadows = {
  shadow4:
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  header: '0 1px 1px rgba(0,0,0,0.15)',
  selectBlack:
    'rgba(50, 50, 93, 0.11) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px 1px 3px',
  selectOptions:
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  button: ' 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
  buttonAddToCart:
    'rgba(0, 0, 0, 0.25) 0px 4px 4px, rgba(0, 0, 0, 0.04) 0px 1px 4px;',
} as const;
