import { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import useMediaQuery from '~/common/hooks/useMediaQuery';
import Social from '~/layout/components/socials';
import { NavigationRow } from '~/layout/styles';
import { Colors, Theme } from '~/theme';

interface FooterProps {
  color?: string;
  fontColor?: Colors;
  isMobileNavigationOpen: boolean;
  showSocial?: boolean;
  theme?: Theme;
}
const Footer: FC<FooterProps> = withTheme(
  ({ fontColor, showSocial = true }) => {
    const { isTablet } = useMediaQuery();

    return isTablet ? (
      <MobileFooter>
        {showSocial && (
          <SocialWrapper>
            <Social fontColor={fontColor} />
          </SocialWrapper>
        )}
      </MobileFooter>
    ) : (
      <StyledFooter>
        <SocialWrapper>
          <Social fontColor={fontColor} />
        </SocialWrapper>
      </StyledFooter>
    );
  },
);

export const MobileFooter = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.navigation};
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SocialWrapper = styled.div`
  position: absolute;
  bottom: 1.5rem;
  z-index: ${({ theme }) => theme.zIndex.navigation};
  right: 1.5rem;
`;

export const StyledFooter = styled(NavigationRow)`
  bottom: 0;
  flex-direction: column;
  height: unset;
  width: unset;
  width: 100%;
`;

export default Footer;
