import { FC } from 'react';
import socialItems from '~/common/constants/socialItems';
import { Link } from '@remix-run/react';
import styled from 'styled-components';

interface SocialProps {
  fontColor?: string;
}

const Social: FC<SocialProps> = ({ fontColor }) => {
  return (
    <IconsWrapper>
      {socialItems.map(({ icon, link, key }) => (
        <Icon
          fill={fontColor}
          key={key}
          to={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {icon}
        </Icon>
      ))}
    </IconsWrapper>
  );
};

export const IconsWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const Icon = styled(Link)<{ fill?: string }>`
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  svg {
    fill: ${(props) => (props.fill ? props.fill : props.theme.colors.text)};
  }

  &:hover {
    svg {
      fill: ${(props) => props.theme.colors.textHover};
    }
  }
`;

export default Social;
