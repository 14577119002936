import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTiktok = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    viewBox="0 0 24 25"
    {...props}
  >
    <g clipPath="url(#tikTok)">
      <path d="M22.515 6.518A6.038 6.038 0 0 1 18.867 5.3 6.052 6.052 0 0 1 16.455.52h-3.913v10.69l-.004 5.857A3.541 3.541 0 0 1 8.79 20.6a3.527 3.527 0 0 1-1.62-.497 3.541 3.541 0 0 1-1.73-2.984 3.544 3.544 0 0 1 4.665-3.414V9.733a7.7 7.7 0 0 0-1.128-.084c-2.166 0-4.19.9-5.638 2.522a7.34 7.34 0 0 0-1.852 4.427 7.33 7.33 0 0 0 2.184 5.711 7.503 7.503 0 0 0 5.306 2.172c.38 0 .758-.028 1.128-.083a7.478 7.478 0 0 0 4.177-2.089 7.326 7.326 0 0 0 2.198-5.213l-.02-8.745a9.89 9.89 0 0 0 6.067 2.062V6.517l-.012.001Z" />
    </g>
    <defs>
      <clipPath id="tikTok">
        <path d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTiktok;
