import { useRef } from 'react';
import useMediaQuery from '~/common/hooks/useMediaQuery';
import { Box } from '~/components/wrappers/Box';
import DesktopSidebar from './Desktop';
import MobileSidebar from './Mobile';
import { Colors } from '~/theme';
import styled from 'styled-components';
import { zIndex } from '~/theme/zIndex';
import { useLocation } from '@remix-run/react';
import { Routes } from '~/common/constants/routes';

interface ISidebar {
  isMobileNavigationOpen: boolean;
  toggleMobileNavigation: (val: boolean) => void;
  fontColor?: Colors;
  activeTab?: string;
}

const Sidebar = ({
  fontColor,
  isMobileNavigationOpen,
  toggleMobileNavigation,
  activeTab,
}: ISidebar) => {
  const { isTablet, isTabletLgx } = useMediaQuery();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const showMobile =
    isTablet || (pathname === Routes.SizingReference && isTabletLgx);

  return (
    <Box zIndex={zIndex.high}>
      <SidebarFixed
        ref={sidebarRef}
        ml={pathname === Routes.SizingReference && isTabletLgx ? '0' : '1.5rem'}
      >
        {showMobile ? (
          <MobileSidebar
            fontColor={fontColor}
            isMobileNavigationOpen={isMobileNavigationOpen}
            toggleMobileNavigation={toggleMobileNavigation}
            activeTab={activeTab}
          />
        ) : (
          <DesktopSidebar fontColor={fontColor} activeTab={activeTab} />
        )}
      </SidebarFixed>
    </Box>
  );
};

const SidebarFixed = styled(Box)`
  position: fixed;
  bottom: 0;

  @media ${({ theme }) => theme.breakpoints.md} {
    margin-left: 0;
  }
`;

export default Sidebar;
