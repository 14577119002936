import { FC, useState, useRef } from 'react';
import styled, { withTheme } from 'styled-components';
import { Root } from './styles';
import Header from './components/header';
import { Colors, Theme } from '~/theme';
import Footer from './components/footer';
import { useCartSSR } from '~/recoil/useCartSSR';
import Sidebar from './components/sidebar';
import { Scrollbar } from './components/scrollbar';
import { useDevice } from '~/common/hooks/useDevice';
import { useLocation } from '@remix-run/react';
import useMediaQuery from '~/common/hooks/useMediaQuery';
import { Routes } from '~/common/constants/routes';
import { colors } from '~/theme/colors';
import { ActiveTab } from '~/types/activeTab';

interface LayoutProps {
  children: JSX.Element | JSX.Element[];
  showLogo?: boolean;
  theme?: Theme;
}

const Layout: FC<LayoutProps> = withTheme(
  ({ children, showLogo = true }: LayoutProps) => {
    const [{ count }] = useCartSSR();
    const { pathname } = useLocation();
    const { isMobile } = useMediaQuery();
    const { isTouchDevice } = useDevice();

    const showSocial =
      !(pathname === Routes.Cart || (pathname === Routes.Shop && isMobile)) &&
      !isTouchDevice;

    const fontColor =
      pathname === Routes.Home ? (colors.primary as Colors) : undefined;

    const [isMobileNavigationOpen, toggleMobileNavigation] =
      useState<boolean>(false);

    const activeTab = pathname === Routes.Shop ? ActiveTab.Product : undefined;

    const rootRef = useRef<HTMLDivElement>(null);
    const { isTablet } = useMediaQuery();

    return (
      <Root ref={rootRef}>
        <Header
          cartItemCount={count}
          showLogo={showLogo}
          fontColor={fontColor}
          isMobileNavigationOpen={isMobileNavigationOpen}
        />
        <StyledMain>
          <Sidebar
            fontColor={fontColor}
            isMobileNavigationOpen={isMobileNavigationOpen}
            toggleMobileNavigation={toggleMobileNavigation}
            activeTab={activeTab}
          />
          {children}
        </StyledMain>
        <Footer
          isMobileNavigationOpen={isMobileNavigationOpen}
          fontColor={fontColor}
          showSocial={showSocial}
        />
        {isTouchDevice || isTablet ? null : <Scrollbar rootRef={rootRef} />}
      </Root>
    );
  },
);

const StyledMain = styled.main`
  display: flex;
  flex-direction: row;
`;

export default withTheme(Layout);
