import styled from 'styled-components';
import navigationItems from '~/common/constants/navigation';
import { Box } from '~/components/wrappers/Box';
import { NavigationItem } from '~/layout/styles';
import { Colors } from '~/theme';

interface IDesktopSidebar {
  fontColor?: Colors;
  activeTab?: string;
}

const DesktopSidebar = ({ fontColor, activeTab }: IDesktopSidebar) => {
  return (
    <>
      {navigationItems.map((item) => {
        return (
          <Box key={item.title} mb="2rem">
            <StyledNavigationItem
              to={item.location}
              fontWeight={500}
              color={fontColor}
              className={activeTab === item.title ? 'active' : ''}
            >
              {item.title}
            </StyledNavigationItem>
          </Box>
        );
      })}
    </>
  );
};

const StyledNavigationItem = styled(NavigationItem)`
  width: fit-content;
`;

export default DesktopSidebar;
