import { FC, useRef, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

interface ScrollbarProps {
  rootRef: React.RefObject<HTMLDivElement>;
}

export const Scrollbar: FC<ScrollbarProps> = ({ rootRef }) => {
  const scrollTrackRef = useRef<HTMLDivElement>(null);
  const scrollThumbRef = useRef<HTMLDivElement>(null);
  const observer = useRef<ResizeObserver | null>(null);
  const [thumbHeight, setThumbHeight] = useState(20);

  const [initialScrollTop, setInitialSrollTop] = useState<number>(0);
  const [scrollStartPosition, setScrollStartPosition] = useState<number | null>(
    null,
  );

  const [isDragging, setIsDragging] = useState(false);

  const handleResize = (ref: HTMLDivElement, trackSize: number) => {
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    const { clientHeight } = ref;

    if (scrollTrackRef.current) {
      if (windowHeight >= clientHeight) {
        scrollTrackRef.current.style.visibility = 'hidden';
      } else {
        scrollTrackRef.current.style.visibility = 'visible';
      }
    }
    setThumbHeight(Math.max((trackSize / clientHeight) * trackSize, 20));
  };

  const handleThumbPosition = useCallback(() => {
    if (!rootRef.current || !scrollThumbRef.current || !scrollTrackRef.current)
      return;

    const { scrollHeight } = rootRef.current;

    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    const { clientHeight: trackHeight } = scrollTrackRef.current;

    let newTop = (+scrollTop / +scrollHeight) * trackHeight;
    newTop = Math.min(newTop, trackHeight - thumbHeight);

    scrollThumbRef.current.style.top = `${newTop}px`;
  }, [rootRef, thumbHeight]);

  useEffect(() => {
    if (rootRef.current && scrollTrackRef.current) {
      const rootElement = rootRef.current;
      const { clientHeight: trackSize } = scrollTrackRef.current;

      observer.current = new ResizeObserver(() => {
        handleResize(rootElement, trackSize);
      });
      observer.current.observe(rootElement);
      window.addEventListener('scroll', handleThumbPosition);

      return () => {
        observer.current?.unobserve(rootElement);
        window.removeEventListener('scroll', handleThumbPosition);
      };
    }
  }, [handleThumbPosition, rootRef]);

  const handleThumbMousedown = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      setScrollStartPosition(event.clientY);
      setInitialSrollTop(window.scrollY);
      setIsDragging(true);
    },
    [],
  );

  const handleThumbMouseup = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (isDragging) {
        setIsDragging(false);
      }
    },
    [isDragging],
  );

  const hanleThumbMousemove = useCallback(
    (event: MouseEvent) => {
      if (isDragging && scrollThumbRef.current && rootRef.current) {
        const scrollDiff = event.clientY - (scrollStartPosition || 0);
        const pageHeight = rootRef.current.clientHeight;
        const viewportHeight = window.innerHeight;

        const scrollableDistance = viewportHeight - thumbHeight;
        const scrollAmount = (scrollDiff / scrollableDistance) * pageHeight;

        window.scrollTo(0, initialScrollTop + scrollAmount);
      }
    },
    [initialScrollTop, isDragging, rootRef, scrollStartPosition, thumbHeight],
  );

  useEffect(() => {
    document.addEventListener('mousemove', hanleThumbMousemove);
    document.addEventListener('mouseup', handleThumbMouseup);
    document.addEventListener('mouseleave', handleThumbMouseup);

    return () => {
      document.removeEventListener('mousemove', hanleThumbMousemove);
      document.removeEventListener('mouseup', handleThumbMouseup);
      document.removeEventListener('mouseleave', handleThumbMouseup);
    };
  }, [hanleThumbMousemove, handleThumbMouseup]);

  return (
    <ScrollbarBase>
      <ScrollbarTrack ref={scrollTrackRef}>
        <ScrollbarThumb
          thumbHeight={thumbHeight}
          ref={scrollThumbRef}
          onMouseDown={handleThumbMousedown}
        ></ScrollbarThumb>
      </ScrollbarTrack>
    </ScrollbarBase>
  );
};

const ScrollbarBase = styled.div`
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  width: 1.25rem;
  z-index: ${({ theme }) => theme.zIndex.high};
`;
const ScrollbarTrack = styled.div`
  height: 100%;
  position: relative;
`;
const ScrollbarThumb = styled.div<{ thumbHeight: number }>`
  position: absolute;
  background: ${({ theme }) => theme.colors.borderGrey};
  height: ${({ thumbHeight }) => thumbHeight}px;
  width: 0.625rem;
  border-radius: 0.625rem;

  :hover {
    background: ${({ theme }) => theme.colors.scrollHover};
  }
`;
