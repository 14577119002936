import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import navigationItems from '~/common/constants/navigation';
import { Box } from '~/components/wrappers/Box';
import { NavigationItem } from '~/layout/styles';
import gsap from 'gsap';
import { Flex } from '~/components/wrappers/Flex';
import Social from '../socials';
import { SocialWrapper } from '../footer';

const MobileMenu = ({
  isOpen,
  activeTab,
}: {
  isOpen?: boolean;
  activeTab?: string;
}) => {
  const mobileMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const menu = mobileMenuRef.current;
    if (!menu) return;

    if (isOpen) {
      document.body.style.overflow = 'hidden';

      gsap.to(menu, {
        duration: 0.2,
        y: 0,
        ease: 'power2.out',
      });
    } else {
      document.body.style.overflow = 'auto';

      gsap.to(menu, {
        duration: 0,
        y: '100%',
        ease: 'power2.out',
      });
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <MobileMenuContainer ref={mobileMenuRef}>
      <Flex
        width="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
        minHeight="22.5rem"
      >
        {navigationItems.map((item) => {
          return (
            <StyledBox key={item.title}>
              <NavigationItem
                to={item.location}
                style={{ width: 'fit-content' }}
                className={activeTab === item.title ? 'active' : ''}
              >
                {item.title}
              </NavigationItem>
            </StyledBox>
          );
        })}
      </Flex>
      <StyledWrapper>
        <Social />
      </StyledWrapper>
    </MobileMenuContainer>
  );
};

export const Logo = styled.img`
  height: 4.9375rem;
  cursor: pointer;
  margin-bottom: 4rem;
`;

const StyledBox = styled(Box)`
  :not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
`;

const StyledWrapper = styled(SocialWrapper)`
  @media (max-height: 23.4375rem) {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

const MobileMenuContainer = styled(Box)`
  position: fixed;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.primary};
  bottom: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  transform: translateY(100%);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  overflow-y: hidden;

  @media (max-height: 23.4375rem) {
    display: block;
    overflow-y: scroll;
  }
`;

export default MobileMenu;
