import React, { useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { Box } from './wrappers/Box';

const InitialLoader = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  const loaderRef = React.useRef<HTMLDivElement>(null);

  const closeLoader = () => {
    if (!loaderRef.current) return;

    gsap.to(loaderRef.current, {
      duration: 0.8,
      opacity: 0,
      display: 'powr4.inOut',
      onComplete: () => {
        setIsLoading(false);
        document.body.style.overflow = 'auto';
      },
    });
  };

  useEffect(() => {
    const initialTimeout = setTimeout(() => {
      closeLoader();
    }, 2000);

    const observer = new PerformanceObserver((list) => {
      const entries = list.getEntriesByType(
        'resource',
      ) as PerformanceResourceTiming[];

      if (entries && entries.length > 0) {
        const ongoingRequests = entries.filter(
          (entry) => entry.transferSize === 0 && entry.duration === 0,
        );

        if (ongoingRequests.length === 0) {
          clearTimeout(initialTimeout); // Clear the timeout to prevent it from hiding the loader again
          setTimeout(() => {
            closeLoader();
          }, 1000);
          observer.disconnect();
        }
      }
    });

    observer.observe({ entryTypes: ['resource'] });

    return () => {
      clearTimeout(initialTimeout);
      observer.disconnect();
    };
  }, []);

  if (!isLoading) {
    return null;
  }

  return (
    <StyledLoaderWrapper ref={loaderRef} className="loader-wrapper">
      <StyledBox className="loader">
        <video autoPlay muted playsInline width={249} height={332} loop>
          <source
            src="https://cdn.shopify.com/videos/c/o/v/9dff0bd52693447da334b140fd78d277.mp4"
            type="video/mp4"
          />
        </video>
      </StyledBox>
    </StyledLoaderWrapper>
  );
};

const StyledLoaderWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: ${({ theme }) => theme.zIndex.loader};
  background-color: ${({ theme }) => theme.colors.buttonText};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.buttonText};
  mix-blend-mode: darken;
`;

export default InitialLoader;
