import { useEffect, useRef } from 'react';

import { gsap } from 'gsap';
import styled from 'styled-components';
import { Flex } from '~/components/wrappers/Flex';
import { Box } from '~/components/wrappers/Box';
import { Colors } from '~/theme';
import { colors } from '~/theme/colors';
import { zIndex } from '~/theme/zIndex';
import { useLocation } from '@remix-run/react';

interface IBurgerButton {
  setIsOpen: (isOpen: boolean) => void;
  fontColor?: Colors;
  fontColorHover?: Colors;
  isOpen?: boolean;
}
const BURGER_LINE_WIDTH = 36;

const BurgerButton = ({ fontColor, setIsOpen, isOpen }: IBurgerButton) => {
  const { pathname } = useLocation();
  const line1Ref = useRef(null);
  const line2Ref = useRef(null);
  const line3Ref = useRef(null);

  const handleOpenAnimation = () => {
    gsap.to(line1Ref.current, {
      duration: 1,
      rotation: 45,
      y: 10,
      backgroundColor: colors.textActive,
      ease: 'power2.out',
    });
    gsap.to(line3Ref.current, {
      duration: 1,
      rotation: -45,
      y: -10,
      backgroundColor: colors.textActive,
      ease: 'power2.out',
    });

    gsap.to(line2Ref.current, {
      duration: 0.5,
      opacity: 0,
      width: 0,
      backgroundColor: colors.textActive,
      ease: 'power2.out',
    });
  };

  const handleCloseAnimation = () => {
    gsap.to(line1Ref.current, {
      duration: 1,
      rotation: 0,
      y: 0,
      backgroundColor: fontColor || colors.textActive,
      ease: 'power2.out',
    });
    gsap.to(line3Ref.current, {
      duration: 1,
      rotation: 0,
      y: 0,
      backgroundColor: fontColor || colors.textActive,
      ease: 'power2.out',
    });

    gsap.to(line2Ref.current, {
      duration: 1,
      width: BURGER_LINE_WIDTH,
      opacity: 1,
      backgroundColor: fontColor || colors.textActive,
      ease: 'power2.out',
    });
  };

  const handleClick = () => {
    if (isOpen) {
      handleCloseAnimation();
      setIsOpen(false);
    } else {
      handleOpenAnimation();
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleCloseAnimation();
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Flex
      cursor="pointer"
      border="none"
      direction="column"
      justifyContent="center"
      aria-label="burger button"
      alignItems="center"
      onClick={handleClick}
      p="1.5rem"
      position="relative"
      zIndex={zIndex.navigation}
    >
      <Flex position="relative" cursor="pointer" direction="column">
        <BurgerLine
          className="burger-line"
          ref={line1Ref}
          fontColor={fontColor}
          mb="0.5rem"
        />
        <BurgerLine
          className="burger-line"
          ref={line2Ref}
          fontColor={fontColor}
          mb="0.5rem"
        />
        <BurgerLine
          className="burger-line"
          ref={line3Ref}
          fontColor={fontColor}
        />
      </Flex>
    </Flex>
  );
};

export default BurgerButton;

const BurgerLine = styled(Box)<{ fontColor?: Colors }>`
  cursor: pointer;
  width: ${BURGER_LINE_WIDTH}px;
  height: 0.125rem;
  opacity: 1;
  background-color: ${({ theme, fontColor }) =>
    fontColor ? fontColor : theme.colors.textActive};
`;
