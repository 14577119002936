import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPinterest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    {...props}
  >
    <g clipPath="url(#pinterest)">
      <path d="M9.417 22.438c1 .302 2.01.479 3.083.479A10.417 10.417 0 1 0 2.083 12.5c0 4.427 2.782 8.23 6.709 9.73-.094-.813-.188-2.157 0-3.084L9.99 14s-.303-.604-.303-1.563c0-1.437.896-2.51 1.917-2.51.896 0 1.313.656 1.313 1.5 0 .896-.594 2.177-.896 3.406-.177 1.021.541 1.917 1.583 1.917 1.854 0 3.292-1.98 3.292-4.77 0-2.5-1.792-4.21-4.365-4.21-2.937 0-4.666 2.188-4.666 4.49 0 .896.291 1.803.77 2.396.094.063.094.146.063.302l-.302 1.136c0 .177-.115.24-.292.114C6.771 15.625 6 13.73 6 12.198c0-3.292 2.333-6.281 6.833-6.281 3.584 0 6.375 2.573 6.375 5.99 0 3.583-2.218 6.458-5.395 6.458-1.01 0-2-.542-2.355-1.177l-.698 2.468c-.24.896-.895 2.094-1.343 2.813v-.032Z" />
    </g>
    <defs>
      <clipPath id="pinterest">
        <path d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPinterest;
