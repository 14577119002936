import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    {...props}
  >
    <g clipPath="url(#mail)">
      <path d="M20.833 4.167H4.167A2.08 2.08 0 0 0 2.094 6.25l-.01 12.5a2.09 2.09 0 0 0 2.083 2.083h16.666a2.09 2.09 0 0 0 2.084-2.083V6.25a2.09 2.09 0 0 0-2.084-2.083Zm0 4.166L12.5 13.542 4.167 8.333V6.25l8.333 5.208 8.333-5.208v2.083Z" />
    </g>
    <defs>
      <clipPath id="mail">
        <path d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMail;
